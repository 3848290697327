import React from 'react'
import classes from './Hero.module.css'
import Moment from 'moment'

const Hero = ({ link, title, image, datePublished, alt }) => {
  // const regex = /(<([^>]+)>)/ig;
  return (
    <div className={classes.Hero}>
      { image.length > 0 &&
        <a className={classes.HeroImg} href={ link } target="_blank" rel="noreferrer">
          <img
            src={ image }
            alt={ alt }
          />
        </a>
      }
      <div className={classes.HeroContent}>
        <a className={classes.HeroTitle} href={ link } aria-label="news link" target="_blank" rel="noreferrer"><h2 dangerouslySetInnerHTML={{ __html: title }} /></a>
        <p className={classes.HeroDate}>{ Moment(datePublished).format('MMM DD, YYYY') }</p>
      </div>
    </div>
  )
}

export default Hero
